<template>
  <b-form-group :label="$t('E-Mail')" label-for="email">
    <b-form-input
        @blur="$v.personData.email.$touch()"
        id="email"
        v-model="personData.email"
        type="email"
        placeholder="Email Adresi Giriniz"
    />
    <small
        v-if="isNext && !$v.personData.email.required"
        class="form-text text-danger"
    >{{ $t('This field is required') }}</small
    >
    <small
        v-if="isNext && !$v.personData.email.email"
        class="form-text text-danger"
    >{{ $t('The email field must be a valid email') }}</small
    >
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'PrecheckPersonEmail',
  props: {
    personData: {
      type: Object, // person data pass
      required: true,
    },
    isNext: {
      type: Boolean, // butona tıklandı mı next mı?
      required: true,
    },
    $v: {
      type: null, // $v validasyon özel bir prop olduğu için null olarak tanımlanmıştır.
      required: true,
    }
  },
  components: {
    BFormInput,
    BFormGroup,
  },
  watch: {
    'personData.email': function (newValue) {
      this.personData.email = newValue.toLocaleLowerCase('tr', 'TR', 'tr-TR') // girilen mail inputunu izler lower case yapar
    }
  }
}
</script>

<style scoped>

</style>
